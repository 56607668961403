
import { baseApi } from './baseApi';

export const actionApi = baseApi.enhanceEndpoints({ addTagTypes: ['BasicLoanDto', 'ConsolidatedTasksDto', 'LinkActionInviteToLoanResponseDto'] }).injectEndpoints({
    endpoints: (build) => ({
        doAction: build.mutation<void, { id: string }>({
            query: (data) => ({
                url: `/v1/action`,
                method: 'GET',
                data,
            }),
            invalidatesTags: [
                { type: 'BasicLoanDto', id: 'LIST' },
                'LinkActionInviteToLoanResponseDto',
                { type: 'ConsolidatedTasksDto', id: "LIST" }],
        }),
        validateAction: build.mutation<boolean, { id: string }>({
            query: (data) => ({
                url: `/v1/action/validate`,
                method: 'GET',
                data,
            })
        }),
    }),
    overrideExisting: true,
})

export const {
    useDoActionMutation,
    useValidateActionMutation,
} = actionApi;



