import { createSlice } from '@reduxjs/toolkit';
import { api } from 'src/api'
import { loanApi } from 'src/services/loanApi';
import type { AppThunk, AppThunkPromise } from 'src/store';
import { Loan } from 'src/types/loan';
import type { IViewState, LoanPackageType } from 'src/types/view';
import { notifyBugTracker } from 'src/utils/notify-bug-tracker';

const initialState: IViewState = {
    currentName: "Test User",
    hasProcessed: false,
    filters: {},
};

const viewSlice = createSlice({
    name: 'view',
    initialState,
    reducers: {}
});

export const { reducer } = viewSlice;

export const updateLoanStatus = ({ loanId, status }: { status: string, loanId: string }): AppThunkPromise => async (): Promise<boolean | Loan> => {
    try {
        const data = await api.updateLoanStatus({ loanId, status });
        return data;
    } catch (error) {
        notifyBugTracker(error);
    }
    return false;
};

// initiate generating loan package zip file
export const postCreateLoanPackage = ({ loanId }: { loanId: string }): AppThunk => async (dispatch, getState): Promise<void> => {
    await api.postCreateLoanPackage({ loanId })
    dispatch(loanApi.util.invalidateTags(['BasicLoanDto']))
}
// initiate generating loan package zip file
export const getLoanPackage = ({ loanId, type }: { loanId: string, type: LoanPackageType }): AppThunk => async (_, getState): Promise<void> => {
    await api.getLoanPackage({ loanId, type })
}


export default viewSlice;
