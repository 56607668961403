import { AddDetailFieldsDto, DetailFieldUpdateValuesDto, DetailGroupsResponseDto } from 'src/backend';

import { baseApi } from './baseApi';

const sortByDisplayOrder = <T extends { displayOrder: number }>(a: T, b: T) => a.displayOrder - b.displayOrder;

export const loanDetailApi = baseApi.enhanceEndpoints({ addTagTypes: ['DetailGroupsResponseDto'] }).injectEndpoints({
    endpoints: (build) => ({
        getDetailsForLoan: build.query<DetailGroupsResponseDto, { loanId: string }>({
            query: (data) => ({
                url: `/v2/detail/loan/${data.loanId}`,
                method: 'GET',
            }),
            transformResponse: (response: DetailGroupsResponseDto) => {
                // sort fields by displayOrder
                // and groups and fields inside groups by displayOrder
                const sortedFields = response.fields.sort(sortByDisplayOrder);
                const sortedGroups = response.groups.sort(sortByDisplayOrder);
                sortedGroups.forEach(group => {
                    group.fields = group.fields.sort(sortByDisplayOrder);
                });
                return { ...response, groups: sortedGroups, fields: sortedFields };
            },
            providesTags: ['DetailGroupsResponseDto']
        }),
        addDetailsForLoan: build.mutation<DetailGroupsResponseDto, { loanId: string, payload: AddDetailFieldsDto }>({
            query: (data) => ({
                url: `/v2/detail/loan/${data.loanId}`,
                method: 'POST',
                data: data.payload,
            }),
            invalidatesTags: ['DetailGroupsResponseDto']
        }),
        updateDetailsForLoan: build.mutation<DetailGroupsResponseDto, { loanId: string, payload: DetailFieldUpdateValuesDto }>({
            query: (data) => ({
                url: `/v2/detail/loan/${data.loanId}/values`,
                method: 'PUT',
                data: data.payload,
            }),
            invalidatesTags: ['DetailGroupsResponseDto']
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetDetailsForLoanQuery,
    useAddDetailsForLoanMutation,
    useUpdateDetailsForLoanMutation
} = loanDetailApi;



