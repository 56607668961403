import Text from 'components/dist/atoms/Text';
import dynamic from 'next/dynamic';
import React from 'react';


const PageErrorGraph = dynamic(() => import('src/icons/page-error-graph').then(m => m.PageErrorGraph), { ssr: false });

export const AppErrorBoundary = () => (<div className='flex flex-col h-screen w-screen items-center justify-center'>
    <div className='flex-1 flex flex-col items-center justify-center w-full max-w-md text-center gap-4'>
        <PageErrorGraph />
        <Text weight="medium" size="xl">Something went wrong</Text>
        <Text variant='secondary'>
            Page unable to load due to a system error. Please refresh page to continue.
        </Text>
        <Text variant='secondary'>If problem persists please  <a
            className='underline'
            href="mailto:support@mysherpas.ai">contact support.</a></Text>
    </div>
</div>)