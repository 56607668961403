// a recursive function to transform data as input and transform it to text tree structure
export function transformTaskTreeToTextTree(data: Record<string, any>, whitespace = ''): string {
    let result = '';
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const element = data[key];
            if (!['title', 'contextId'].includes(key)) {
                result += `${whitespace}`;
                if (whitespace !== '') {
                    result += " * ";
                }
                result += `${key}\n`;
            }
            if (typeof element === 'object') {

                result += transformTaskTreeToTextTree(element, `${whitespace}${" ".repeat(2)}`);
            }
        }
    }
    return result;
}