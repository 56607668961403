import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import Router from 'next/router'
import { createSelector } from 'reselect';
import { api } from 'src/api';
import { BorrowUserSetDto, LeadUserSetDto, LoanDto, LoanPhaseCategoryType, LoanReviewStatus, LoanViewType } from 'src/backend';
import { ActionStatus, Route } from 'src/constants/ui';
import { loanApi } from 'src/services/loanApi';
import { notificationApi } from 'src/services/notificationApi';
import { taskApi } from 'src/services/taskApi';
import type { AppThunk, AppThunkPromise, RootState } from 'src/store';
import { Loan } from 'src/types/loan';
import { API_STATUS } from 'src/types/view'
import { transformLoan } from 'src/utils/loan/transform-loan';
import { notifyBugTracker } from 'src/utils/notify-bug-tracker';
import { toast } from 'src/utils/toast';

import { entitySlice } from './entity';
import { loansManagerSlice } from './loan-manager';
import { getAllLoanUsersTasks } from './task';
import { handleRequestError } from './ui';

const DEFAULT_LOGO_KEY = 'default-logo';

interface LoanState {
    pendingGetLoan: Record<string, string>,
    primaryLenderName: string,
    loans: Record<string, Loan>;
    logos: Record<string, string>;
    createLoanState: API_STATUS,
    createLoanErrors: Record<string, string>
    createdLoan?: Loan | null,
    // when entityType is Person or DBA, we save the loan values in this object, until a person is created.
    // then we save both the loan and the person and invite the created person to the loan.
    unsavedFormLoan: object,
    loansCountByPhaseCategory: Record<LoanPhaseCategoryType, number>,
    // a map of loanId to the loan view type
    viewType: Record<string, LoanViewType | null>,
}

const initialState: LoanState = {
    logos: {},
    primaryLenderName: '',
    pendingGetLoan: {},
    loans: {},
    loansCountByPhaseCategory: {
        'ARCHIVE': 0,
        'ORIGINATION': 0,
        'LEAD': 0,
        'PORTFOLIO': 0,
    },
    createLoanState: 'idle',
    createLoanErrors: {},
    createdLoan: null,
    unsavedFormLoan: null,
    viewType: {},
};



export const loanSlice = createSlice({
    name: 'loan',
    initialState,
    reducers: {
        createLoanStateChange(state: LoanState, action: PayloadAction<API_STATUS>): void {
            state.createLoanState = action.payload
        },
        createdLoan(state: LoanState, action: PayloadAction<Loan>): void {
            state.createdLoan = action.payload
        },
        createLoanErrors(state: LoanState, action: PayloadAction<{ errors: { [x: string]: string } }>): void {
            state.createLoanErrors = action.payload.errors
        },
        togglePendingGetLoan(state: LoanState, action: PayloadAction<string>): void {
            if (typeof state.pendingGetLoan === 'undefined') {
                state.pendingGetLoan = {};
            }
            // add or remove pending get loan
            if (state.pendingGetLoan?.[action.payload]) {
                delete state.pendingGetLoan[action.payload]
            } else {
                state.pendingGetLoan[action.payload] = action.payload
            }
        },
        setLogo(state: LoanState, action: PayloadAction<{ id: string, url: string }>): void {
            state.logos[action.payload.id] = action.payload.url
        },
        setPrimaryLenderName(state: LoanState, action: PayloadAction<string>): void {
            state.primaryLenderName = action.payload;
        },
        unsavedFormLoan(state: LoanState, action: PayloadAction<object>): void {
            state.unsavedFormLoan = action.payload
        }
    }
});

export const { reducer } = loanSlice;


export const updateLoanPhase = ({ loanId, phaseId }: { loanId: string, phaseId: string }): AppThunkPromise => async (dispatch): Promise<void> => {
    try {
        await api.updateLoanPhase({ loanId, phaseId });
        await dispatch(getLoan(loanId, false, true));
        dispatch(loanApi.util.invalidateTags(['BasicLoanDto']));
        dispatch(notificationApi.util.invalidateTags(['NotificationBellDto']));
    } catch (e) { }
}

export const createdLoan = (loan: Loan | null): AppThunk => async (dispatch): Promise<void> => {
    dispatch(loanSlice.actions.createdLoan(loan))
}

export const setLoanReviewStatus = (loanId: string, status: LoanReviewStatus): AppThunkPromise<LoanDto> => async (dispatch): Promise<LoanDto> => {
    const loan = await api.setLoanReadyToReviewStatus(loanId, status);
    dispatch(loanApi.util.invalidateTags(['BasicLoanDto']));
    dispatch(setLoan(loan));
    return loan
}


export const setLoan = (loan: Loan): AppThunk => async (dispatch): Promise<void> => {
    const sanitizeHtml = (await import('sanitize-html')).default;
    const sanitizedLoan = {
        ...loan,
        loanPurpose: sanitizeHtml(loan.loanPurpose)
    }

    dispatch(loansManagerSlice.actions.appendLoans({
        loans: [sanitizedLoan],
    }));
    dispatch(loanApi.util.invalidateTags([{ type: 'BasicLoanDto', id: loan.id }, { type: 'LoanDto', id: loan.id }]));
    const entities = {
        entities: loan?.loanEntities?.map(loanEntity => ({
            ...loanEntity,
            ...loanEntity.mostRecentUserInformation,
            ...loanEntity.sherpaEntity,
            ...loanEntity.assetEntityCustomizations,
            id: loanEntity.id,
            label: loanEntity.label,
            customizations: loanEntity.assetEntityCustomizations,
            templateId: loanEntity.templateId,
            templateName: loanEntity.templateName,
        })),
        loanId: loan.id
    }

    dispatch(entitySlice.actions.setLoanEntities(entities));
}

let PendingAbortControllers: AbortController[] = [];

export const getLoan = (id: string, skip: boolean = true, clearPendingRequest: boolean = false): AppThunkPromise<Loan> => async (dispatch, getState): Promise<Loan> => {
    const { loan: { pendingGetLoan } } = getState();
    if (clearPendingRequest) {
        PendingAbortControllers.forEach(abortController => abortController.abort());
    }
    if (pendingGetLoan?.[id] && skip) return;
    dispatch(loanSlice.actions.togglePendingGetLoan(id))
    const pendingAbortController = new AbortController();
    try {
        PendingAbortControllers.push(pendingAbortController);
        const result = await api.getLoan(id, pendingAbortController.signal);
        if (typeof result?.id !== 'undefined') {
            dispatch(setLoan(result));
            dispatch(loanSlice.actions.togglePendingGetLoan(id));
        }
        dispatch(loanSlice.actions.togglePendingGetLoan(id));
        return result;
    } catch (error) {
        dispatch(loanSlice.actions.togglePendingGetLoan(id));
        if (error.status === 404) {
            toast({
                type: 'error',
                content: `Loan either does not exist or you are not authorized to access, please contact your mysherpas admin if this continues`
            })
        }
        // if loan is not found 
        // or if error happened 
        // and we are on loan page,
        // redirect to homepage
        const isUserOnLoanPage = Router.pathname === Route.SINGLE_LOAN
        const isRequestError = error.status === 404 || error.status === 400 || error.status === 403;
        if (isRequestError && isUserOnLoanPage) {
            Router.replace({
                pathname: Route.HOME,
            });
        }
    } finally {
        // remove current abort controller from pending requests
        PendingAbortControllers = PendingAbortControllers.filter(abortController => abortController !== pendingAbortController);
    }
}

export const reGenerateCreditReport = ({ loanId, individualId }: {
    loanId: string,
    individualId: string
}) => async (): Promise<void> => {
    await api.rescheduleReport(loanId, individualId)
}

export const addLenderToLoan = ({ loanId, payload }: {
    loanId: string,
    payload: LeadUserSetDto
}): AppThunkPromise<Loan> => async (dispatch): Promise<Loan> => {
    const loan = await api.addLender(loanId, payload);
    dispatch(taskApi.util.invalidateTags([{ type: 'ConsolidatedTasksDto', id: loanId }]));
    dispatch(loanApi.util.invalidateTags([{ type: 'BasicLoanDto', id: loanId }, { type: 'LoanDto', id: loanId }]));
    dispatch(setLoan(loan));
    return loan;
}

export const editLenderOnLoan = ({ loanId, payload }: {
    loanId: string,
    payload: LeadUserSetDto
}): AppThunkPromise<Loan> => async (dispatch): Promise<Loan> => {
    const loan = await api.editLender(loanId, payload);
    dispatch(loanApi.util.invalidateTags([{ type: 'BasicLoanDto', id: loanId }, { type: 'LoanDto', id: loanId }]));
    dispatch(taskApi.util.invalidateTags([{ type: 'ConsolidatedTasksDto', id: loanId }])); dispatch(getAllLoanUsersTasks(loanId));
    dispatch(getAllLoanUsersTasks(loanId));
    dispatch(setLoan(loan));
    return loan;
}

export const removeRoleFromLoan = (loanId: string, roleId: string): AppThunk => async (dispatch): Promise<void> => {
    try {
        const loan = await api.removeLender(loanId, roleId);
        dispatch(taskApi.util.invalidateTags([{ type: 'ConsolidatedTasksDto', id: loanId }]));
        dispatch(loanApi.util.invalidateTags([{ type: 'BasicLoanDto', id: loanId }, { type: 'LoanDto', id: loanId }]));
        dispatch(setLoan(loan));
    } catch (error) {
        notifyBugTracker(error);
        dispatch(handleRequestError(error));
    }
}

export const addBorrowerUser = ({ loanId, payload }: {
    loanId: string,
    payload: BorrowUserSetDto
}): AppThunkPromise<Loan> => async (dispatch): Promise<Loan> => {
    try {
        const loan = await api.addBorrowerUser(loanId, payload);
        if (payload.sendInvite) {
            toast({
                content: `Invitation Sent!`,
                type: ActionStatus.success
            });
        }
        if (typeof loan?.id !== 'undefined') {
            dispatch(setLoan(loan));
        }
        return loan;

    } catch (error) {
        dispatch(handleRequestError(error));
    }
}

export const setUserRoleOnLoan = ({ loanId, payload }: {
    loanId: string,
    payload: LeadUserSetDto
}): AppThunkPromise<Loan> => async (dispatch): Promise<Loan> => {
    try {
        const loan = await api.setUserRole(loanId, payload);
        dispatch(taskApi.util.invalidateTags(['ConsolidatedTasksDto']));
        dispatch(loanApi.util.invalidateTags([{ type: 'BasicLoanDto', id: loanId }, { type: 'LoanDto', id: loanId }]));
        dispatch(getAllLoanUsersTasks(loanId));
        dispatch(setLoan(loan));
        return loan;
    } catch (error) {
        dispatch(handleRequestError(error));
    }
}

// selectors
export const createLoanErrorsSelector = (state: RootState) => state.loan.createLoanErrors;

export const loanSelector = (loanId: string) => createSelector(
    [(state: RootState) => state[loansManagerSlice.name].loans[loanId]],
    (loan) => transformLoan(loan)
);

export default loanSlice;