
import { IdDto, ShoeboxItemRequestDto, ShoeboxItemResponseDto } from 'src/backend';

import { baseApi } from './baseApi';

export const lenderShoeBoxApi = baseApi.enhanceEndpoints({ addTagTypes: ['ShoeboxItemResponseDto'] }).injectEndpoints({
    endpoints: (build) => ({
        getMyShoeBoxItems: build.query<ShoeboxItemResponseDto[], void>({
            query: () => ({
                url: `/v2/shoebox/lender`,
                method: 'GET',
            }),
            providesTags: ['ShoeboxItemResponseDto'],
        }),
        createShoeBoxItem: build.mutation<ShoeboxItemResponseDto, ShoeboxItemRequestDto>({
            query: (data) => ({
                url: `/v2/shoebox/item`,
                method: 'POST',
                data,
            }),
            invalidatesTags: ['ShoeboxItemResponseDto'],
        }),
        getLoanShoeBoxItems: build.query<ShoeboxItemResponseDto[], { loan: string }>({
            query: (data) => ({
                url: `/v2/shoebox/loan`,
                method: 'GET',
                data
            }),
            providesTags: ['ShoeboxItemResponseDto'],
            transformResponse: (response: ShoeboxItemResponseDto[]) => {
                // sort alphabetically
                return response.sort((a, b) => a.title.localeCompare(b.title, 'en', { numeric: true }));
            },
        }),
        findShoeBoxItem: build.query<ShoeboxItemResponseDto, IdDto>({
            query: (data) => ({
                url: `/v2/shoebox/item/${data.id}`,
                method: 'GET',
            }),
            providesTags: ['ShoeboxItemResponseDto'],
        }),
        updateShoeBoxItem: build.mutation<ShoeboxItemResponseDto, ShoeboxItemRequestDto>({
            query: (data) => ({
                url: `/v2/shoebox/item`,
                method: 'PUT',
                data,
            }),
            invalidatesTags: ['ShoeboxItemResponseDto'],
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    lenderShoeBoxApi.util.updateQueryData('getMyShoeBoxItems', null, (draft) => {
                        // remove the item from the list
                        const index = draft.findIndex((item) => item.id === id)
                        if (index !== -1) {
                            draft.splice(index, 1)
                        }
                    })
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                    /**
                     * Alternatively, on failure you can invalidate the corresponding cache tags
                     * to trigger a re-fetch:
                     * dispatch(api.util.invalidateTags(['Post']))
                     */
                }
            },
        }),
        deleteShoeBoxItem: build.mutation<void, IdDto>({
            query: (data) => ({
                url: `/v2/shoebox/item`,
                method: 'DELETE',
                data,
            }),
            invalidatesTags: ['ShoeboxItemResponseDto'],
        }),
    }),
    overrideExisting: true,
})

export const {
    useFindShoeBoxItemQuery,
    useGetLoanShoeBoxItemsQuery,
    useGetMyShoeBoxItemsQuery,
    useCreateShoeBoxItemMutation,
    useUpdateShoeBoxItemMutation,
    useDeleteShoeBoxItemMutation,
} = lenderShoeBoxApi;



