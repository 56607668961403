import type { ElementModifierType } from "src/backend";

import { ListItem } from "./lists";

export const KnowledgeBase = {
    PRINCIPALS: 'PRINCIPALS',
    APPLICANT: 'APPLICANT',
    TRUST: "TRUST",
    PDF_PACKAGE: "PDF_PACKAGE",
    ZIP_PACKAGE: "ZIP_PACKAGE",
    COMPANY: "COMPANY",
    SHOE_BOX: 'SHOE_BOX',
    COLLATERAL_ASSET_REAL_ESTATE: 'COLLATERAL_ASSET_REAL_ESTATE',
    COLLATERAL_ASSET: 'COLLATERAL_ASSET',
    SUBJECT_COLLATERAL: "SUBJECT_COLLATERAL",
    SUBJECT_COLLATERAL_REAL_ESTATE: "SUBJECT_COLLATERAL_REAL_ESTATE"
} as const;

export const Type = {
    SECTION: 'FOLDER',
    FOLDER: 'FOLDER',
    FILE: 'FILE',
} as const;

export const ZIP_IGNORE_FILES = [
    '.DS_Store'
] as const;

export const FILE_CARD_MINIMAL_DISPLAY = 'MINIMAL';

export const FILE_CARD_FULL_DISPLAY = 'FULL';

export const FileCardDisplay = {
    MINIMAL: 'MINIMAL',
    FULL: 'FULL',
} as const;

export const OverViewPackageDisplay = {
    PRINCIPAL: 'PRINCIPAL',
    USER: 'USER'
} as const;

export const FormElementPriority = {
    Hidden: 'HIDDEN',
    High: 'HIGH',
    Low: 'LOW',
    Medium: 'MEDIUM',
    Private: 'PRIVATE',
    Urgent: 'URGENT',
} as const;

export const QUERY_CREATE_FILE_ELEMENT_DIALOG = 'createFileElementDialog';

export const QUERY_PARAM_CONFIRM_TASKS_VIEW_CLOSE_IN_PROGRESS_ELEMENTS = 'tasksInProgressElementsConfirmation';

export const QUERY_PARAM_PACKAGE_UNASSIGNED_FILTER = 'packageUnAssignedFilter';

export const QUERY_PARAM_FORM_ELEMENT_TITLE = 'formElementTitle';

export const QUERY_PARAM_FULL_SCREEN_PREVIEW_FORM_ELEMENT_ID = 'fullScreenElementId';

export const QUERY_PARAM_FORM_ELEMENT_IDS = 'formElementIds';

export const QUERY_PARAM_TEMPLATE_ID = 'templateId';

export const QUERY_PARAM_NEEDS_LIST_DIALOG = 'needsListDialog';

export const QUERY_PARAM_PICK_NEEDS_LIST_LOAN_DIALOG = 'pickNeedsListLoanDialog';

export const DEFAULT_FROM_ELEMENT_DISPLAY_ORDER = 1000;

export const PINNED_FROM_ELEMENT_DISPLAY_ORDER = 1001;

export enum UploadFileStrategy {
    Merge = 'MERGE',
    Replace = 'REPLACE',
    MergeOnly = 'MERGE_ONLY',
};

export enum SimpleViewFolderNames {
    Business = "Business Docs",
    BusinessAndTrust = "Business and Trust Docs",
    Collateral = "Collateral Docs",
    Individual = "Individual Docs",
    Project = "Project Docs",
}

const ElementModifierTypeList: Record<ElementModifierType, ElementModifierType> = {
    "ALL": "ALL",
    "NONE": "NONE",
    "LENDER_ONLY": "LENDER_ONLY",
    "SIGNATURE_REQUIRED": "SIGNATURE_REQUIRED",
    "EDITABLE_XLS": "EDITABLE_XLS",
    "BILLING_DOCUMENT": "BILLING_DOCUMENT",
    "INCLUDE_ANSWERED_UNACCEPTED_IN_TASK": "INCLUDE_ANSWERED_UNACCEPTED_IN_TASK",
    "FILLABLE_FORM": "FILLABLE_FORM",
    "HIDE_ON_CREATE": "HIDE_ON_CREATE",
    "AUDITABLE": "AUDITABLE",
    "NEEDS_LEGAL_REVIEW": "NEEDS_LEGAL_REVIEW",
    "SYSTEM_FORM": "SYSTEM_FORM",
    "NEEDS_SIGNATURE": "NEEDS_SIGNATURE",
    "NEEDS_NOTARY": "NEEDS_NOTARY",
    "NEEDS_WET_SIG": "NEEDS_WET_SIG",
    "NEEDS_SHIP_LABEL": "NEEDS_SHIP_LABEL",
    "INTERNAL_ONLY": "INTERNAL_ONLY",
    "EXTERNAL_DATA": "EXTERNAL_DATA",
    "SELF_SIGN": "SELF_SIGN"
};


export const elementModifiers: ListItem[] = Object.keys(ElementModifierTypeList).map(key => ({
    label: key,
    value: key
}));