
export const getErrors = (errors: any) => {
    if (!errors) {
        return {};
    }
    let errorObj = {};
    errors.forEach(error => {
        errorObj[error.field] = error.defaultMessage;
    });
    return errorObj;
}