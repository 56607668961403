import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api } from 'src/api';
import { TemplateTypeMapsDto } from 'src/backend';


interface TemplateTypeV2State extends TemplateTypeMapsDto {
}

const initialState: TemplateTypeV2State = {
    blockTypeMap: {},
    loanViewTypeMap: {},
    locationTypeMap: {},
    entityModifierTypeMap: {},
    namingStrategyTypeMap: {},
    priorityTypeMap: {},
    sherpaEntityTypeMap: {},
    storageTypeMap: {},
    elementModifierTypeMap: {},
};

export const templateV2TypesSlice = createSlice({
    name: 'templatev2Type',
    initialState,
    reducers: {
        setTypes(
            state: TemplateTypeV2State,
            action: PayloadAction<TemplateTypeMapsDto>
        ): void {
            state.blockTypeMap = action.payload.blockTypeMap;
            state.loanViewTypeMap = action.payload.loanViewTypeMap;
            state.locationTypeMap = action.payload.locationTypeMap;
            state.namingStrategyTypeMap = action.payload.namingStrategyTypeMap;
            state.priorityTypeMap = action.payload.priorityTypeMap;
            state.sherpaEntityTypeMap = action.payload.sherpaEntityTypeMap;
            state.storageTypeMap = action.payload.storageTypeMap;
            state.entityModifierTypeMap = action.payload.entityModifierTypeMap;
            state.elementModifierTypeMap = action.payload.elementModifierTypeMap;
        }
    }
});


export const getV2TemplateTypes = () => async (dispatch: any) => {
    const types = await api.getV2TemplateTypes();
    dispatch(actions.setTypes(types));
}

export const { reducer, actions } = templateV2TypesSlice;

// create selectors for each type map
export const blockTypeMapSelector = (state: { templatev2Type: TemplateTypeV2State }): TemplateTypeMapsDto['blockTypeMap'] => {
    return state.templatev2Type.blockTypeMap;
}

export const loanViewTypeMapSelector = (state: { templatev2Type: TemplateTypeV2State }): TemplateTypeMapsDto['loanViewTypeMap'] => {
    return state.templatev2Type.loanViewTypeMap;
}

export const locationTypeMapSelector = (state: { templatev2Type: TemplateTypeV2State }): TemplateTypeMapsDto['locationTypeMap'] => {
    return state.templatev2Type.locationTypeMap;
}

export const namingStrategyTypeMapSelector = (state: { templatev2Type: TemplateTypeV2State }): TemplateTypeMapsDto['namingStrategyTypeMap'] => {
    return state.templatev2Type.namingStrategyTypeMap;
}

export const priorityTypeMapSelector = (state: { templatev2Type: TemplateTypeV2State }): TemplateTypeMapsDto['priorityTypeMap'] => {
    return state.templatev2Type.priorityTypeMap;
}

export const sherpaEntityTypeMapSelector = (state: { templatev2Type: TemplateTypeV2State }): TemplateTypeMapsDto['sherpaEntityTypeMap'] => {
    return state.templatev2Type.sherpaEntityTypeMap;
}

export const storageTypeMapSelector = (state: { templatev2Type: TemplateTypeV2State }): TemplateTypeMapsDto['storageTypeMap'] => {
    return state.templatev2Type.storageTypeMap;
}

export const entityModifierTypeMapSelector = (state: { templatev2Type: TemplateTypeV2State }): TemplateTypeMapsDto['entityModifierTypeMap'] => {
    return state.templatev2Type.entityModifierTypeMap;
}