
import { CategoryDto, SupportRequestDto, SupportResponseDto } from 'src/backend';

import { baseApi } from './baseApi';

export const learningCenterApi = baseApi.enhanceEndpoints({ addTagTypes: ['CategoryDto', 'SupportResponseDto'] }).injectEndpoints({
    endpoints: (build) => ({
        getLearningCenterCategories: build.query<CategoryDto[], void>({
            query: () => ({
                url: `/v2/support/categories`,
                method: 'GET',
            }),
            providesTags: ['CategoryDto'],
        }),
        createLearningCenterCategory: build.mutation<CategoryDto, CategoryDto>({
            query: (data) => ({
                url: `/v2/support/category`,
                method: 'POST',
                data,
            }),
            invalidatesTags: ['CategoryDto'],
        }),
        updateLearningCenterCategory: build.mutation<CategoryDto, CategoryDto>({
            query: (data) => ({
                url: `/v2/support/category`,
                method: 'PUT',
                data,
            }),
            invalidatesTags: ['CategoryDto'],
        }),
        deleteLearningCenterCategory: build.mutation<CategoryDto, CategoryDto>({
            query: (data) => ({
                url: `/v2/support/category`,
                method: 'DELETE',
                data,
            }),
            invalidatesTags: ['CategoryDto'],
        }),
        incrementLearningCenterEntryViewCount: build.mutation<SupportResponseDto, { supportId: string }>({
            query: (data) => ({
                url: `/v2/support/views/increment/${data.supportId}`,
                method: 'PUT',
                data,
            }),
            invalidatesTags: ['SupportResponseDto'],
        }),
        getLearningCenterEntries: build.query<SupportResponseDto[], void>({
            query: () => ({
                url: `/v2/support/entries`,
                method: 'GET',
            }),
            providesTags: ['SupportResponseDto'],
        }),
        createLearningCenterEntry: build.mutation<SupportResponseDto, SupportRequestDto>({
            query: (data) => ({
                url: `/v2/support/entry`,
                method: 'POST',
                data,
            }),
            invalidatesTags: ['SupportResponseDto'],
        }),
        updateLearningCenterEntry: build.mutation<SupportResponseDto, SupportRequestDto>({
            query: (data) => ({
                url: `/v2/support/entry`,
                method: 'PUT',
                data,
            }),
            invalidatesTags: ['SupportResponseDto'],
        }),
        deleteLearningCenterEntry: build.mutation<SupportResponseDto, { id: string }>({
            query: (data) => ({
                url: `/v2/support/entry/${data.id}`,
                method: 'DELETE',
                data,
            }),
            invalidatesTags: ['SupportResponseDto'],
        }),
    }),
    overrideExisting: true,
})

export const {
    useIncrementLearningCenterEntryViewCountMutation,
    useGetLearningCenterCategoriesQuery,
    useCreateLearningCenterCategoryMutation,
    useUpdateLearningCenterCategoryMutation,
    useDeleteLearningCenterCategoryMutation,
    useCreateLearningCenterEntryMutation,
    useUpdateLearningCenterEntryMutation,
    useDeleteLearningCenterEntryMutation,
    useGetLearningCenterEntriesQuery,
} = learningCenterApi;



