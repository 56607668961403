import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppUserDTO2, AppUserSignupRequestDto, BorrowUserSetDto, LinkActionInviteToLoanResponseDto } from "src/backend";
import { AppThunkPromise, RootState } from "src/store";

import { api } from "../api";

interface UserState {
    pendingInvites: LinkActionInviteToLoanResponseDto[]
}

const initialState: UserState = {
    pendingInvites: [],
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setPendingInvites: (state, action: PayloadAction<LinkActionInviteToLoanResponseDto[]>) => {
            state.pendingInvites = action.payload;
        },
    }
})

export const AddUser = (body: AppUserSignupRequestDto): AppThunkPromise<AppUserDTO2> => async (): Promise<AppUserDTO2> => {
    return api.addUser(body);
};

export const editBorrowUser = (loanId: string, userId: string, body: BorrowUserSetDto): AppThunkPromise<AppUserDTO2> => async (): Promise<AppUserDTO2> => {
    const user = await api.editBorrowUser(loanId, userId, body);
    return user;
};

export const getPendingInvites = (): AppThunkPromise<LinkActionInviteToLoanResponseDto[]> => async (dispatch): Promise<LinkActionInviteToLoanResponseDto[]> => {
    const invites = await api.getPendingInvites();
    dispatch(actions.setPendingInvites(invites));
    return invites;
};


export const selectPendingInvites = (state: RootState): LinkActionInviteToLoanResponseDto[] => state.user.pendingInvites;

const { actions } = userSlice;
