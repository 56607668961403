export const LOAN_MESSAGES_PER_PAGE = 20;

export const QUERY_MESSAGE_THREAD_ID = 'messageThreadId';

export const QUERY_MESSAGE_ID = 'messageId';

export const QUERY_MESSAGE_SEARCH_LABEL = 'messageSearchLabel';

export const QUERY_MESSAGE_SEARCH_TEXT = 'messageSearchText';

export const QUERY_PARAM_MESSAGE_LENDER_ID = 'messageLenderId';

export const QUERY_MESSAGE_COMPOSE_OPEN = 'messageComposeOpen';

export const QUERY_MESSAGE_RECIPIENTS_IDS = 'messageRecipientsIds';