import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { api } from "src/api";
import { SupportRequestDto, SupportResponseDto } from "src/backend";

const SLICE_NAME = 'learning-center'

const entriesAdapter = createEntityAdapter<SupportResponseDto>({
    selectId: (entry) => entry.id,
    sortComparer: (a, b) => String(a.title).localeCompare(b.title)
});

// By default, `createEntityAdapter` gives you `{ ids: [], entities: {} }`.
// If you want to track 'loading' or other keys, you would initialize them here:
// `getInitialState({ loading: false, activeRequestId: null })`
const initialState = entriesAdapter.getInitialState({
    loading: false
})

export const fetchEntries = createAsyncThunk(
    `${SLICE_NAME}/fetchEntries`, async () => {
        const response = await api.getLearningCenterEntries()
        return {
            entries: response,
        }
    }
)

export const updateEntry = createAsyncThunk(
    `${SLICE_NAME}/updateEntry`, async (entry: SupportRequestDto) => {
        const response = await api.updateLearningCenterEntry(entry)
        return response
    }
)

export const createEntry = createAsyncThunk(
    `${SLICE_NAME}/createEntry`, async (entry: SupportRequestDto) => {
        const response = await api.createLearningCenterEntry(entry)
        return response
    }
)

export const removeEntry = createAsyncThunk(
    `${SLICE_NAME}/removeEntry`, async (entryId: string) => {
        await api.deleteLearningCenterEntry(entryId)
        return entryId
    }
)

export const learningCenterSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchEntries.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchEntries.fulfilled, (state, action) => {
            entriesAdapter.upsertMany(state, action.payload.entries)
            state.loading = false
        })
        builder.addCase(fetchEntries.rejected, (state) => {
            state.loading = false
        }
        )
        builder.addCase(updateEntry.pending, (state) => {
            state.loading = true
        }
        )
        builder.addCase(updateEntry.fulfilled, (state, action) => {
            entriesAdapter.upsertOne(state, action.payload)
            state.loading = false
        })
        builder.addCase(updateEntry.rejected, (state) => {
            state.loading = false
        }
        )
        builder.addCase(createEntry.pending, (state) => {
            state.loading = true
        }
        )
        builder.addCase(createEntry.fulfilled, (state, action) => {
            entriesAdapter.upsertOne(state, action.payload)
            state.loading = false
        })
        builder.addCase(createEntry.rejected, (state) => {
            state.loading = false
        }
        )
        builder.addCase(removeEntry.pending, (state) => {
            state.loading = true
        }
        )
        builder.addCase(removeEntry.fulfilled, entriesAdapter.removeOne)
        builder.addCase(removeEntry.rejected, (state) => {
            state.loading = false
        }
        )
    },
})

export const {
    selectById: selectEntryById,
    selectIds: selectEntryIds,
    selectEntities: selectEntries,
    selectAll: selectAllEntries,
    selectTotal: selectTotalEntries
} = entriesAdapter.getSelectors(state => state[SLICE_NAME]);
