import axios from "axios";

// load a file from url using axios and determine mimetype
export const getFileFromUrl = async (url: string, fileName?: string): Promise<File | null> => {
    try {
        const response = await axios.get(url, { responseType: 'blob' });
        const blob = response.data;
        const splitName = url.split('/').pop();
        const file = new File([blob], fileName ?? splitName, { type: blob.type });
        return file;
    } catch (error) {
        console.error(error);
        return null;
    }
}