/*
 * try to read the first byte of the file.
 * If we are able to read the first byte, it's a file.
 * If an error is thrown, it's probably a directory:
 */
export const isAFile = async (file: File): Promise<boolean> => {
    try {
        await file.slice(0, 1).arrayBuffer();
        return true;
    }
    catch (err) {
        return false;
    }
}