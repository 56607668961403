import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { api } from 'src/api';
import type {
    AssetEntityCustomizationsDto,
    BankAssetRequestDto, BankAssetResponseDto,
    BusinessRequestDto, BusinessResponseDto, EntityCreateDto,
    EntityLabelDto, EntityResponseDto, EquipmentRequestDto, EquipmentResponseDto,
    IndividualResponseDto,
    LoanDto, LoanEntityDto, LoanEntityLabel, NewEntityTemplateDto, RandomAssetRequestDto, RandomAssetResponseDto,
    RealEstateRequestDto,
    RealEstateResponseDto,
    SherpaEntityType
} from 'src/backend';
import { AppThunkPromise, RootState } from 'src/store';
import { sortLoanEntities } from 'src/utils/loan/sort-loan-entities';

export type SherpaEntityWithInformation =
    EntityResponseDto
    & Partial<LoanEntityDto>
    & { assetEntityCustomizations?: AssetEntityCustomizationsDto, label: LoanEntityLabel[] }
    & Partial<RealEstateResponseDto>
    & Partial<BusinessResponseDto>
    & Partial<IndividualResponseDto>;

interface EntityState {
    assets: Record<string, EntityResponseDto[]>;
    businesses: Record<string, EntityResponseDto[]>;
    loanEntities: Record<string, SherpaEntityWithInformation[]>;
    loanEntitiesRequestInProgress: Record<string, boolean>;
}

const initialState: EntityState = {
    assets: {},
    businesses: {},
    loanEntities: {},
    loanEntitiesRequestInProgress: {},
};

export const entitySlice = createSlice({
    name: 'entity',
    initialState,
    reducers: {
        setBusinesses(state: EntityState, action: PayloadAction<EntityResponseDto[]>): void {
            action.payload.forEach(business => {
                if (!state.businesses[business.id]) {
                    state.businesses[business.id] = [];
                }
                state.businesses[business.id].push(business);
            }
            );
        },
        setLoanEntities(state: EntityState, action: PayloadAction<{
            entities: SherpaEntityWithInformation[],
            loanId: string
        }>): void {
            state.loanEntities[action.payload.loanId] = action.payload.entities
        },
        removeEntityFromLoan(state: EntityState, action: PayloadAction<{ loanId: string, entityId: string }>): void {
            state.loanEntities[action.payload.loanId] = state.loanEntities[action.payload.loanId].filter(entity => entity.id !== action.payload.entityId)
        },
        setLoanEntitiesRequestInProgress(state: EntityState, action: PayloadAction<{
            loanId: string,
            inProgress: boolean
        }>): void {
            state.loanEntitiesRequestInProgress[action.payload.loanId] = action.payload.inProgress;
        }
    }
});

export const renameEntity = (entityId: string, title: string, loanId: string): AppThunkPromise<void> => async (dispatch: any): Promise<void> => {
    await api.renameEntity(entityId, { title });
}

export const removeEntityFromLoan = (loanId: string, entityId: string): AppThunkPromise<void> => async (dispatch: any): Promise<void> => {
    await api.removeEntityFromLoan(loanId, entityId);
    dispatch(actions.removeEntityFromLoan({ loanId, entityId }));

}

export const createBusinesses = (create: EntityCreateDto): AppThunkPromise<EntityResponseDto> => async (dispatch: any): Promise<EntityResponseDto> => {
    const result = await api.createBusinesses(create);
    dispatch(actions.setBusinesses([result]));

    return result
}

export const addEntityLabel = (applicantRequest: EntityLabelDto): AppThunkPromise<LoanDto> => async (dispatch: any): Promise<LoanDto> => {
    return api.addEntityLabel(applicantRequest);
}

const getEntityRealEstateInformation = (entityId: string): AppThunkPromise<RealEstateResponseDto> => async (dispatch: any): Promise<RealEstateResponseDto> => {
    return api.getEntityRealEstateInformation(entityId);
};

const getEntityEquipmentInformation = (entityId: string) => async (dispatch: any): Promise<EquipmentResponseDto> => {
    return api.getEntityEquipmentInformation(entityId);
}

const getBankAssetInformation = (entityId: string): AppThunkPromise<BankAssetResponseDto> => async (dispatch: any): Promise<BankAssetResponseDto> => {
    return api.getBankAssetInformation(entityId);
}

const getRandomAssetInformation = (entityId: string): AppThunkPromise<RandomAssetResponseDto> => async (dispatch: any): Promise<RandomAssetResponseDto> => {
    return api.getRandomAssetInformation(entityId);
}

export const addRandomAssetInformation = (entityId: string, asset: RandomAssetRequestDto): AppThunkPromise<RandomAssetResponseDto> => async (dispatch: any): Promise<RandomAssetResponseDto> => {
    await api.addRandomAssetInformation(entityId, asset);
    return dispatch(getRandomAssetInformation(entityId));
}

export const addBankAssetInformation = (entityId: string, bankAccount: BankAssetRequestDto): AppThunkPromise<BankAssetResponseDto> => async (dispatch: any): Promise<BankAssetResponseDto> => {
    await api.addBankAssetInformation(entityId, bankAccount);
    return dispatch(getBankAssetInformation(entityId));
}

export const addEntityEquipmentInformation = (entityId: string, equipment: EquipmentRequestDto): AppThunkPromise<EquipmentResponseDto> => async (dispatch: any): Promise<EquipmentResponseDto> => {
    await api.addEntityEquipmentInformation(entityId, equipment);
    return dispatch(getEntityEquipmentInformation(entityId));
}

export const addEntityRealEstateInformation = (entityId: string, realEstate: RealEstateRequestDto): AppThunkPromise<RealEstateResponseDto> => async (dispatch: any): Promise<RealEstateResponseDto> => {
    await api.addEntityRealEstateInformation(entityId, realEstate);
    return dispatch(getEntityRealEstateInformation(entityId));
}

const getEntityBusinessInformation = (entityId: string): AppThunkPromise<EntityResponseDto> => async (dispatch: any): Promise<EntityResponseDto> => {
    return api.getEntityBusinessInformation(entityId);
};

export const addBusinessEntityInformation = (entityId: string, information: BusinessRequestDto): AppThunkPromise<RealEstateResponseDto> => async (dispatch: any): Promise<RealEstateResponseDto> => {
    await api.postBusinessEntityInformation(entityId, information);
    return dispatch(getEntityBusinessInformation(entityId));
}

export const applyNewTemplateToExistingEntity = (payload: NewEntityTemplateDto): AppThunkPromise<LoanDto> => async (dispatch: any): Promise<LoanDto> => {
    const loan = await api.applyNewTemplateToExistingEntity(payload);
    return loan;
}

export const selectLoanEntities = (loanId: string) => (state: RootState): SherpaEntityWithInformation[] => {
    return (Object.values(state[entitySlice.name].loanEntities[loanId] ?? {}) ?? [].sort(sortLoanEntities));
}

export const { reducer, actions } = entitySlice;
