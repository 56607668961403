import { ConsolidatedTasksDto, htmlDto } from 'src/backend';

import { baseApi } from './baseApi';

export const taskApi = baseApi.enhanceEndpoints({ addTagTypes: ['ConsolidatedTasksDto'] }).injectEndpoints({
    endpoints: (build) => ({
        getConsolidatedTasks: build.query<ConsolidatedTasksDto, { loanId: string }>({
            query: (data) => ({
                url: `/v2/tasks/consolidated/${data.loanId}`,
                method: 'GET',
            }),
            providesTags: (res, __, args) => {
                const tasksList = Object.values(res.perUser ?? {}).map(task => task.forMe).flat()

                return [
                    ...tasksList.map<{ type: 'ConsolidatedTasksDto', id: string }>(task => ({
                        type: "ConsolidatedTasksDto",
                        id: task.contextId
                    })),
                    { type: 'ConsolidatedTasksDto', id: args.loanId },
                    { type: 'ConsolidatedTasksDto', id: "LIST" },
                ]
            }
        }),
        tasksForLoanAndUserAsHtml: build.query<string, { loanId: string, userId?: string }>({
            query: (data) => ({
                url: `/v1/tasks/html`,
                method: 'GET',
                data
            }),
            transformResponse: (response: htmlDto) => {
                return response.html;
            }
        }),
        getConsolidatedTasksForLoans: build.query<ConsolidatedTasksDto[], { loanIds: string[] }>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                const promises = _arg.loanIds.map(loanId => fetchWithBQ({ url: `/v2/tasks/consolidated/${loanId}`, method: 'GET' }));
                const results = await Promise.all(promises);
                const data = results.map(result => result.data as ConsolidatedTasksDto);
                return { data, error: null };
            },
            providesTags: (_, __, args) => [
                {
                    type: 'ConsolidatedTasksDto',
                    id: 'LIST'
                },
                ...args.loanIds.map<{
                    type: 'ConsolidatedTasksDto',
                    id: string
                }>(loanId => ({
                    type: "ConsolidatedTasksDto",
                    id: loanId
                }))
            ]
        }),
    }),
    overrideExisting: true,
})

export const {
    useTasksForLoanAndUserAsHtmlQuery,
    useGetConsolidatedTasksQuery,
    useGetConsolidatedTasksForLoansQuery,
    useLazyGetConsolidatedTasksQuery,
} = taskApi;



