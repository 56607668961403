import type { Task } from "src/types/tasks";

// construct a tree based on the data and property fullTitle as breadcrumb
export const getTasksAsTree = (data: Task[]): Record<string, any> => {
    return data.reduce((acc, curr) => {
        const breadcrumb = curr.fullTitle.split('->');
        breadcrumb.reduce((acc2, curr2, index) => {
            if (!acc2[curr2]) {
                acc2[curr2] = {
                    title: curr2,
                    deepLink: breadcrumb.length - 1 === index ? curr.deepLink : undefined
                };
            }
            return acc2[curr2];
        }, acc);

        return acc;
    }, {});
}
