import { createSelector, createSlice } from '@reduxjs/toolkit';
import { api } from 'src/api';
import { LoansKanbanFilter } from 'src/constants/loan';
import { FormElementNavigator, LoanOverviewView, UNAUTHORIZED_ERROR_STATUS } from 'src/constants/ui';
import { LoanKanbanFilterState } from 'src/contexts/loans-kanban-context';
import type { AppThunk, AppThunkPromise, RootState } from 'src/store';
import { TLoanOverviewView, TLoanSidebar } from 'src/types/ui';
import { notifyBugTracker } from 'src/utils/notify-bug-tracker';

type LoansFilterType = keyof typeof LoansKanbanFilter;
interface UiState {
    settingsDrawerVisible?: boolean,
    previewMode?: boolean,
    connectionError?: boolean,
    closeChatAfterAction?: boolean,
    useAlternativeFilePreviewer?: boolean,
    navbarTitle: string,
    openSidebar: TLoanSidebar;
    drawerOpen: boolean;
    leftSidebarExpanded: boolean;
    formElementsSortingEnabled: boolean;
    formElementNavigator: typeof FormElementNavigator[keyof typeof FormElementNavigator];
    formElementTreeMapDepth: number;
    hiddenPhases: string[];
    formElementTreeMapHideFiles: boolean;
    lgUpDrawerOpen: boolean;
    messagesExpanded: boolean;
    activeOverviewView: TLoanOverviewView;
    agreedToTOS: boolean;
    loansFilter: LoansFilterType;
    loansFilterTeamMembersIds: string[];
    loansFilterQuery?: string;
    keyPreferences: Record<string, any>;
    sendEmailAddRemoveUser: boolean,
    sendEmailAppMessage: boolean,
    sendEmailLoanStatus: boolean,
    sendTextAddRemoveUser: boolean,
    sendTextAppMessage: boolean,
    sendTextLoanStatus: boolean,
}

export const initialState: UiState = {
    settingsDrawerVisible: false,
    previewMode: false,
    connectionError: false,
    closeChatAfterAction: false,
    useAlternativeFilePreviewer: false,
    hiddenPhases: [],
    navbarTitle: '',
    openSidebar: null,
    leftSidebarExpanded: false,
    formElementsSortingEnabled: true,
    drawerOpen: undefined,
    formElementNavigator: FormElementNavigator.Simple,
    formElementTreeMapDepth: 2,
    formElementTreeMapHideFiles: false,
    lgUpDrawerOpen: false,
    messagesExpanded: false,
    activeOverviewView: LoanOverviewView.Tasks,
    agreedToTOS: false,
    loansFilter: LoansKanbanFilter.LENDER_LOANS,
    loansFilterTeamMembersIds: [],
    loansFilterQuery: '',
    keyPreferences: {},
    sendEmailAddRemoveUser: true,
    sendEmailAppMessage: true,
    sendEmailLoanStatus: true,
    sendTextAddRemoveUser: true,
    sendTextAppMessage: true,
    sendTextLoanStatus: true,
};

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        hideSettingsDrawer(state: UiState): void {
            state.settingsDrawerVisible = false
        },
        showSettingsDrawer(state: UiState): void {
            state.settingsDrawerVisible = true;
        },
        setIsPreviewMode(state: UiState, action: { payload: boolean }): void {
            state.previewMode = action.payload;
        },
        toggleConnectionError(state: UiState, action: { payload: boolean }): void {
            state.connectionError = action.payload;
        },
        setCloseChatAfterAction(state: UiState, action: { payload: boolean }): void {
            state.closeChatAfterAction = action.payload;
        },
        setUseAlternativeFilePreviewer(state: UiState, action: { payload: boolean }): void {
            state.useAlternativeFilePreviewer = action.payload;
        },
        setNavbarTitle(state: UiState, action: { payload: string }): void {
            state.navbarTitle = action.payload;
        },
        setOpenLoanSidebar(state: UiState, action: { payload: TLoanSidebar }): void {
            state.openSidebar = action.payload;
        },
        setLeftSidebarExpanded(state: UiState, action: { payload: boolean }): void {
            state.leftSidebarExpanded = action.payload;
        },
        setFormElementsSortingEnabled(state: UiState, action: { payload: boolean }): void {
            state.formElementsSortingEnabled = action.payload;
        },
        setDrawerOpen(state: UiState, action: { payload: boolean }): void {
            state.drawerOpen = action.payload;
        },
        setFormElementNavigator(state: UiState, action: { payload: typeof FormElementNavigator[keyof typeof FormElementNavigator] }): void {
            state.formElementNavigator = action.payload;
        },
        setFormElementTreeMapDepth(state: UiState, action: { payload: number }): void {
            state.formElementTreeMapDepth = action.payload;
        },
        setFormElementTreeMapHideFiles(state: UiState, action: { payload: boolean }): void {
            state.formElementTreeMapHideFiles = action.payload;
        },
        openDrawerLgAndUp(state: UiState): void {
            state.lgUpDrawerOpen = true;
            state.drawerOpen = true;
        },
        setHiddenPhases(state: UiState, action: { payload: string[] }): void {
            state.hiddenPhases = action.payload;
        },
        setActiveOverviewView(state: UiState, action: { payload: TLoanOverviewView }): void {
            state.activeOverviewView = action.payload;
        },
        setAgreedToTOS(state: UiState, action: { payload: boolean }): void {
            state.agreedToTOS = action.payload;
        },
        setLoansFilter(state: UiState, action: { payload: LoanKanbanFilterState }): void {
            state.loansFilter = action.payload.loansFilter;
            state.loansFilterTeamMembersIds = action.payload.teamMembersIds || [];
            state.loansFilterQuery = action.payload.filterQuery;
        },
        setKeyPreference(state: UiState, action: { payload: { key: string, value: any } }): void {
            state.keyPreferences[action.payload.key] = action.payload.value;
        },
        setSendEmailAddRemoveUser(state: UiState, action: { payload: boolean }): void {
            state.sendEmailAddRemoveUser = action.payload;
        },
        setSendEmailAppMessage(state: UiState, action: { payload: boolean }): void {
            state.sendEmailAppMessage = action.payload;
        },
        setSendEmailLoanStatus(state: UiState, action: { payload: boolean }): void {
            state.sendEmailLoanStatus = action.payload;
        },
        setSendTextAddRemoveUser(state: UiState, action: { payload: boolean }): void {
            state.sendTextAddRemoveUser = action.payload;
        },
        setSendTextAppMessage(state: UiState, action: { payload: boolean }): void {
            state.sendTextAppMessage = action.payload;
        },
        setSendTextLoanStatus(state: UiState, action: { payload: boolean }): void {
            state.sendTextLoanStatus = action.payload;
        }
    }
});

export const { reducer, actions: uiActions } = uiSlice;

export const toggleDrawer = (open: boolean): AppThunk => async (dispatch) => {
    dispatch(uiSlice.actions.setDrawerOpen(open));
}

export const setAgreedToTOS = (agreed: boolean): AppThunk => async (dispatch) => {
    dispatch(uiSlice.actions.setAgreedToTOS(agreed));
}

export const openDrawerLgAndUp = (): AppThunk => async (dispatch) => {
    dispatch(uiSlice.actions.openDrawerLgAndUp());
}

export const setFormElementTreeMapDepth = (depth: number): AppThunk => async (dispatch) => {
    dispatch(uiSlice.actions.setFormElementTreeMapDepth(depth));
}

export const setActiveOverviewView = (view: TLoanOverviewView): AppThunk => async (dispatch) => {
    dispatch(uiSlice.actions.setActiveOverviewView(view));
}

export const setFormElementTreeMapHideFiles = (hideFiles: boolean): AppThunk => async (dispatch) => {
    dispatch(uiSlice.actions.setFormElementTreeMapHideFiles(hideFiles));
}

export const setFormElementNavigator = (navigator: typeof FormElementNavigator[keyof typeof FormElementNavigator]): AppThunk => async (dispatch) => {
    dispatch(uiSlice.actions.setFormElementNavigator(navigator));
}

export const setFormElementsSortingEnabled = (enabled: boolean): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setFormElementsSortingEnabled(enabled));
}

export const setLeftSidebarExpanded = (expanded: boolean): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setLeftSidebarExpanded(expanded));
}

export const setHiddenPhases = (statuses: string[]): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setHiddenPhases(statuses));
}

export const setOpenLoanSidebar = (openSidebar: TLoanSidebar): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setOpenLoanSidebar(openSidebar));
};

export const showSettingsDrawer = (): AppThunk => async (dispatch): Promise<void> => {
    dispatch(uiSlice.actions.showSettingsDrawer());
};

export const hideSettingsDrawer = (): AppThunk => async (dispatch): Promise<void> => {
    dispatch(uiSlice.actions.hideSettingsDrawer());
};

export const setIsPreviewMode = (previewMode: boolean): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setIsPreviewMode(previewMode));
};

export const toggleConnectionError = (connectionError?: boolean): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.toggleConnectionError(connectionError));
};

export const setCloseChatAfterAction = (closeChatAfterAction: boolean): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setCloseChatAfterAction(closeChatAfterAction));
};

export const setUseAlternativeFilePreviewer = (useAlternativeFilePreviewer: boolean): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setUseAlternativeFilePreviewer(useAlternativeFilePreviewer));
};

export const setNavbarTitle = (title: string): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setNavbarTitle(title));
};

export const setSendEmailAddRemoveUser = (sendEmailAddRemoveUser: boolean): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setSendEmailAddRemoveUser(sendEmailAddRemoveUser));
};

export const setSendEmailAppMessage = (sendEmailAppMessage: boolean): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setSendEmailAppMessage(sendEmailAppMessage));
};

export const setSendEmailLoanStatus = (sendEmailLoanStatus: boolean): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setSendEmailLoanStatus(sendEmailLoanStatus));
};

export const setSendTextAddRemoveUser = (sendTextAddRemoveUser: boolean): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setSendTextAddRemoveUser(sendTextAddRemoveUser));
};

export const setSendTextAppMessage = (sendTextAppMessage: boolean): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setSendTextAppMessage(sendTextAppMessage));
};

export const setSendTextLoanStatus = (sendTextLoanStatus: boolean): AppThunk => (dispatch) => {
    dispatch(uiSlice.actions.setSendTextLoanStatus(sendTextLoanStatus));
};

export const handleRequestError = (error: any) => (dispatch, getState) => {
    const { view: { loggedInUserId } } = getState();
    if (error?.status >= 500 || error?.message === 'Failed to fetch') {
        dispatch(uiSlice.actions.toggleConnectionError(true));
    } else if ([UNAUTHORIZED_ERROR_STATUS, 403].includes(error?.status) && !!loggedInUserId) {
        console.error('request error, reloading page', error)
        document.location.reload();
        notifyBugTracker(error);
    } else {
        dispatch(uiSlice.actions.toggleConnectionError(false));
    }
};

export const getKeyPreference = (key: string): AppThunkPromise<void> => async (dispatch): Promise<void> => {
    const result = await api.getPreference(key)
    dispatch(uiSlice.actions.setKeyPreference({ key, value: JSON.parse(decodeURIComponent(result.value)) }));
};

export const saveKeyPreference = (key: string, value: any): AppThunkPromise<void> => (dispatch, getState) => {
    const oldValue = keyPreferenceSelector(key)(getState());
    dispatch(uiSlice.actions.setKeyPreference({ key, value }));
    return new Promise((resolve, reject) => {
        api.savePreference(key, JSON.stringify(value)).then(() => {
            resolve();
        }).catch((error) => {
            dispatch(uiSlice.actions.setKeyPreference({ key, value: oldValue }));
            reject(error);
        });
    });
}

export const keyPreferenceSelector = (key: string) => createSelector(
    (state: RootState) => state.ui.keyPreferences,
    (keyPreferences) => keyPreferences[key]
);


export const uiSelector = (state: RootState) => state.ui
export const selectDrawerOpen = (state: RootState) => state.ui.drawerOpen
export const selectConnectionError = (state: RootState) => state.ui.connectionError

export const hiddenPhasesSelector = createSelector([
    (state: RootState) => state.ui
], (ui) => ui.hiddenPhases);

export const loansFilterSelector = createSelector<[
    (state: RootState) => string[],
    (state: RootState) => string,
    (state: RootState) => LoansFilterType
], LoanKanbanFilterState>([
    (state: RootState) => state.ui.loansFilterTeamMembersIds,
    (state: RootState) => state.ui.loansFilterQuery,
    (state: RootState) => state.ui.loansFilter
], (ids, loansFilterQuery, loansFilter) => ({
    teamMembersIds: ids,
    loansFilter: loansFilter,
    filterQuery: loansFilterQuery
}))

export default uiSlice;
