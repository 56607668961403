import { AppUserChangePasswordRequestDto, CompanyListDto, SecurityResponseDto, TemplateFieldSetResponseDto, TemplateFieldSetsResponseDto } from 'src/backend';

import { baseApi } from './baseApi';

export const adminApi = baseApi.enhanceEndpoints({ addTagTypes: ['CompanyListDto', 'TemplateFieldSetResponseDto'] }).injectEndpoints({
    endpoints: (build) => ({
        adminResetPassword: build.mutation<SecurityResponseDto, AppUserChangePasswordRequestDto>({
            query: (data) => ({
                url: `/v1/auth/adminResetPassword`,
                method: 'POST',
                data,
            })
        }),
        getCompanies: build.query<CompanyListDto[], void>({
            query: () => ({
                url: '/v1/lenders/companies',
                method: 'GET',
            }),
            transformResponse: (response: CompanyListDto[]) => response.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true })),
        }),
        getFieldsSetsForUser: build.query<TemplateFieldSetResponseDto[], void>({
            query: () => ({
                url: '/v2/templatefields/sets',
                method: 'GET',
            }),
            transformResponse: (response: TemplateFieldSetsResponseDto) => response.templates,
            providesTags: ['TemplateFieldSetResponseDto'],
        }),
        deleteFieldSet: build.mutation<void, string>({
            query: (id) => ({
                url: `/v2/templatefields/set/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['TemplateFieldSetResponseDto'],
        }),
    }),
    overrideExisting: true,
})

export const {
    useDeleteFieldSetMutation,
    useGetFieldsSetsForUserQuery,
    useGetCompaniesQuery,
    useAdminResetPasswordMutation,
} = adminApi;



