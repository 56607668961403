// a recursive function to transform data as input and transform it to text tree structure
export function transformTaskTreeToHTMLTree(data: Record<string, any>, whitespace = ''): string {
    let result = '';
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const element = data[key];
            if (!['title', 'deepLink'].includes(key)) {
                result += `${whitespace}`;
                if (whitespace !== '') {
                    result += " * ";
                }

                if (typeof element.deepLink !== 'undefined') {
                    result += `<a href=${element.deepLink}>${key}</a><br />`;
                } else {
                    result += `${key}<br />`;
                }
            }
            if (typeof element === 'object') {
                result += transformTaskTreeToHTMLTree(element, `${whitespace}${"&nbsp;".repeat(2)}`);
            }
        }
    }
    return result;
}