import type { ProviderType } from "src/backend";

import { getExtensionFromFilename } from "../get-extension-from-filename";

export const guessFileProviderType = (name: string): ProviderType => {
    const extension = getExtensionFromFilename(name);
    if ([
        //word
        'odt',
        'docx',
        'doc',
        'dotx',
        'dot',
        // powerpoint
        'ppsx',
        'pptm',
        'pot',
        'potm',
        'ppsx',
        'pps',
        'pptx',
        'ppt',
        // excel
        'xls',
        'xlsb',
        'xlt',
        'xltm',
        'xltx',
        'xlsx',
        'csv',
        'xlsm'
    ].includes(extension)) {
        return "SHAREPOINT";
    }

    return 'S3';
}