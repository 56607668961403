import { endOfDay, format, formatDistanceToNow, isPast, parseISO } from "date-fns";
import { LoanDto } from "src/backend";
import { LoanDtoExtended } from "src/services/loanApi";

export const transformLoan = (loan: LoanDto): LoanDtoExtended => {
    if (!loan) {
        return null;
    }
    const hasClosedDate = !!loan.closeDate;
    const parsedCloseDate = hasClosedDate ? parseISO(loan.closeDate) : null;
    let closeDateDistance = '';
    try {
        closeDateDistance = hasClosedDate ? formatDistanceToNow(endOfDay(parsedCloseDate)) : '';
    } catch (e) {
    }

    return ({
        ...loan,
        closeDate: loan.closeDate,
        loanProgress: (loan.percentageApproved + loan.percentageAnswered) / 2,
        closeDateDistance,
        closeDateYearFormatted: hasClosedDate ? format(parsedCloseDate, "MMM d yyyy").toUpperCase() : '',
        createdDateYearFormatted: loan.createdDate ? format(parseISO(loan.createdDate), "MMM d yyyy").toUpperCase() : '',
        closeDateFormatted: hasClosedDate ? format(parsedCloseDate, "MMM d, yyyy") : '',
        createdDateFormatted: loan.createdDate ? format(parseISO(loan.createdDate), "MMM d, yyyy") : '',
        closeDateIsPassed: (hasClosedDate && !!parsedCloseDate) ? isPast(endOfDay(parsedCloseDate)) : false,
        templateProjectName: loan.template?.name ? loan.template.name : loan.templateProjectName,
    })
}
