import { DEFAULT_TOAST_DURATION, MAX_TOAST_DURATION } from "src/constants/ui";

import { countWords } from "./count-words";

export const getToastDuration = (text: string): number => {
    const calculatedDuration = getTextReadDurationPerMillisecond(text) * 1.5;
    let duration = DEFAULT_TOAST_DURATION;

    if (calculatedDuration > MAX_TOAST_DURATION) {
        duration = MAX_TOAST_DURATION;
    } else if (calculatedDuration > DEFAULT_TOAST_DURATION) {
        duration = calculatedDuration;
    }

    return duration;
}

const getTextReadDurationPerMillisecond = (text: string) => {
    return Math.ceil(countWords(text) / 3.9) * 1000;
}
