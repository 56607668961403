
export const getUserDisplayName = (user: Partial<{ givenName: string, familyName: string, emailAddress: string, email: string, name: string }>): string => {
    if (!user) {
        return "";
    }
    if (user.givenName?.trim() && user.familyName?.trim()) {
        return `${user.givenName} ${user.familyName}`;
    } else if (user.givenName?.trim()) {
        return user.givenName;
    } else if (user.familyName?.trim()) {
        return user.familyName;
    } else if ('name' in user && user.name?.trim()) {
        return user.name;
    } else if ('email' in user && user.email) {
        return user.email;

    } else if ('emailAddress' in user && user.emailAddress) {
        return user.emailAddress;
    } else {
        return null;
    }
}