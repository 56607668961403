// A function that strips all html tags from a string and returns the text content.
// without using any external libraries.
export const stripHTMLFromString = (htmlString: string): string => {
    // Create a new DOM element
    const tempElement = document.createElement('div');

    // Set the innerHTML of the element to the provided string
    tempElement.innerHTML = htmlString;

    // Extract and return the text content of the element
    return tempElement.textContent || '';
}