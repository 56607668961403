import { createSlice } from "@reduxjs/toolkit";
import { OnboardCompany } from "src/backend";

import { api } from "../api";


const slice = createSlice({
    name: "admin",
    initialState: {},
    reducers: {}
})


export const updateCompany = (companyId: string, company: OnboardCompany) => async (): Promise<void> => {
    const companyResponse = await api.updateCompany(companyId, company);
    return companyResponse;
}

export const { reducer, actions } = slice;


