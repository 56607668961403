export const fileToBase64 = (file: File): Promise<string> => new Promise((
  resolve,
  reject
) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  // This will always be a string because of the above line right?
  reader.onload = () => resolve(reader.result as string);
  reader.onerror = (error) => reject(error);
});
