import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { api, UploadDriverLicenseParams } from 'src/api'
import { InviteToLoanDTO } from 'src/backend';
import { ActionStatus } from 'src/constants/ui';
import type { AppThunk, AppThunkPromise, RootState } from 'src/store';
import type { IDriverLicenseImage } from 'src/types/view';
import { API_STATUS } from 'src/types/view'
import { toast } from 'src/utils/toast';

interface User {
    id: string;
    images?: IDriverLicenseImage
    loanId?: string
}

interface PersonState {
    persons: User[],
    createPersonsErrors: {},
    createPersonStatus: API_STATUS,
    createdPerson: User | null,
    uploadUrls: IDriverLicenseImage,
    driverLicenseImages: IDriverLicenseImage,
    inviteRequestStatus: Record<string, boolean>;
}

const initialState: PersonState = {
    persons: [],
    createPersonsErrors: {},
    createPersonStatus: 'idle',
    createdPerson: null,
    inviteRequestStatus: {},
    uploadUrls: {
        front: null,
        back: null,
        backStatus: 'idle',
        frontStatus: 'idle'
    },
    driverLicenseImages: {
        front: null,
        back: null,
        backStatus: 'idle',
        frontStatus: 'idle',
        backUploadStatus: 'idle',
        frontUploadStatus: 'idle'
    },
};

const slice = createSlice({
    name: 'person',
    initialState,
    reducers: {
        createPersonStateChange(state: PersonState, action: PayloadAction<API_STATUS>): void {
            state.createPersonStatus = action.payload
        },
        createPersonErrors(state: PersonState, action: PayloadAction<{ errors: { [x: string]: any } }>): void {
            state.createPersonsErrors = action.payload.errors
        },
        getDriverLicenseImages(state: PersonState, action: PayloadAction<User>): void {
            const personIndex = state.persons.findIndex((person) => person.id === action.payload.id)
            if (personIndex === -1) {
                state.persons = [
                    ...state.persons,
                    action.payload
                ]
            } else {
                state.persons[personIndex] = action.payload
            }
            state.driverLicenseImages = action.payload.images
        },
        uploadDriverLicense(state: PersonState, action: PayloadAction<IDriverLicenseImage>): void {
            state.driverLicenseImages = {
                ...state.driverLicenseImages,
                ...action.payload
            }
        },
        getDriverLicenseImagesStateChange(state: PersonState, action: PayloadAction<IDriverLicenseImage>): void {
            state.driverLicenseImages = {
                ...state.driverLicenseImages,
                ...action.payload
            }
        },
        getUploadDriverLicenseUrls(state: PersonState, action: PayloadAction<IDriverLicenseImage>): void {
            state.uploadUrls = action.payload
        },
        setInviteRequestStatus(state: PersonState, action: PayloadAction<{ personId: string, status: boolean }>): void {
            state.inviteRequestStatus = {
                ...state.inviteRequestStatus,
                [action.payload.personId]: action.payload.status
            }
        }
    }
});

export const { reducer } = slice;

export const getDriverLicenseImages = (individualId: string, type?: 'front' | 'back'): AppThunk => async (dispatch): Promise<void> => {
    if (typeof individualId === 'undefined' || !individualId) {
        return
    }
    if (type) {
        dispatch(slice.actions.getDriverLicenseImagesStateChange({
            ...(type === 'front' ? { frontStatus: 'loading' } : { backStatus: 'loading' })
        }))
    } else {
        dispatch(slice.actions.getDriverLicenseImagesStateChange({
            frontStatus: 'loading',
            backStatus: 'loading'
        }))
    }
    try {
        const data = await api.getADriversLicense(individualId);
        let front = null, back = null;
        if (data?.dlBack?.id) {
            back = await api.getDocumentDownloadUrl(data.dlBack.id);
        }
        if (data?.dlFront?.id) {
            front = await api.getDocumentDownloadUrl(data.dlFront.id);
        }
        dispatch(slice.actions.getDriverLicenseImages({
            images: {
                front,
                back,
            },
            id: individualId
        }));
    } catch (error) {
    }
    if (type) {
        dispatch(slice.actions.getDriverLicenseImagesStateChange({
            ...(type === 'front' ? { frontStatus: 'idle' } : { backStatus: 'idle' })
        }))
    } else {
        dispatch(slice.actions.getDriverLicenseImagesStateChange({
            frontStatus: 'idle',
            backStatus: 'idle'
        }))
    }
};

export const setUploadDriverLicenseLoadingState = (type: 'front' | 'back'): AppThunk => async (dispatch): Promise<void> => {
    const uploadState = {
        ...(type === 'front' ? {
            frontUploadStatus: ActionStatus.loading,
            front: ''
        } : {
            backUploadStatus: ActionStatus.loading,
            back: ''
        })
    }
    dispatch(slice.actions.uploadDriverLicense(uploadState));
};

export const setUploadDriverLicenseIdleState = (type: 'front' | 'back'): AppThunk => async (dispatch): Promise<void> => {
    const uploadState = {
        ...(type === 'front' ? {
            frontUploadStatus: ActionStatus.idle,
            front: ''
        } : {
            backUploadStatus: ActionStatus.idle,
            back: ''
        })
    }
    dispatch(slice.actions.uploadDriverLicense(uploadState));
};

export const uploadDriverLicense = ({ individualId, file, type, dto }: UploadDriverLicenseParams): AppThunk => async (dispatch): Promise<void> => {
    dispatch(setUploadDriverLicenseLoadingState(type));
    let dlBackDocumentId = null;
    let dlFrontDocumentId = null;
    try {
        const driversLicense = await api.getADriversLicense(individualId);
        dlBackDocumentId = driversLicense?.dlBack?.id ?? null;
        dlFrontDocumentId = driversLicense?.dlFront?.id ?? null
    } catch (e) { }
    const data = await api.uploadDriverLicenseImage({
        individualId: individualId,
        dlBackDocumentId,
        dlFrontDocumentId,
        file,
        type,
        dto
    });
    dispatch(slice.actions.uploadDriverLicense(data));
    dispatch(getDriverLicenseImages(individualId, type))
};


export const inviteUserToLoan = (inviteRequest: InviteToLoanDTO): AppThunkPromise => async (dispatch): Promise<any> => {
    dispatch(slice.actions.setInviteRequestStatus({
        personId: inviteRequest.userId,
        status: true,
    }));
    try {
        const isSuccessful = await api.inviteBorrowerUserToLoan(inviteRequest.loanId, inviteRequest);
        if (isSuccessful) {
            toast({
                content: `Invitation Sent!`,
                type: ActionStatus.success
            });
        }
    } catch (error) {
    } finally {
        dispatch(slice.actions.setInviteRequestStatus({
            personId: inviteRequest.userId,
            status: false,
        }));
    }
}

export const createPersonStatusSelector = (state: RootState) => state.person.createPersonStatus;
export const createPersonErrorsSelector = (state: RootState) => state.person.createPersonsErrors;
export const driverLicenseImagesSelector = (state: RootState) => state.person.driverLicenseImages
export const selectPersonInviteRequestStatus = (personId: string) => (state: RootState) => Boolean(state.person.inviteRequestStatus[personId]);

export default slice;
