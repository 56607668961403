import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { api } from 'src/api';
import { FormElementPackageInfoResponseDto, FormElementV2ResponseDto } from 'src/backend';
import { RootState } from 'src/store';

import { elementsTree } from './elementv2';

export const elementAdapter = createEntityAdapter<FormElementV2ResponseDto>({
    selectId: (element) => element.id,
    sortComparer: (a, b) => String(a.title).localeCompare(b.title),
});

const foldersAdapter = createEntityAdapter<FormElementPackageInfoResponseDto>({
    selectId: (folder) => folder.id,
    sortComparer: (a, b) => String(a.title).localeCompare(b.title),
});


export const fetchFolders = createAsyncThunk(
    'elements/getFolders',
    async (id: string) => {
        const data = await api.getLoanFolders(id)
        return data;
    }
)

export const consolidatedLenderSlice = createSlice({
    name: 'consolidated-folders',
    initialState: foldersAdapter.getInitialState({
        loading: false,
        ids: [],
        entities: {},
        elements: elementAdapter.getInitialState(),
    }),
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchFolders.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchFolders.fulfilled, (state, action) => {
            foldersAdapter.removeAll(state);
            elementAdapter.removeAll(state.elements);
            foldersAdapter.upsertMany(state, action.payload.packageInfo);
            elementAdapter.upsertMany(state.elements, action.payload.elements);
            state.loading = false;
        });
    }
});

export const { reducer } = consolidatedLenderSlice;

// export loading state selector
export const selectFoldersLoading = (state: RootState) => state[consolidatedLenderSlice.name].loading;

export const selectFoldersElementTree = (state: RootState) => {

    const folders = state[consolidatedLenderSlice.name].entities;
    const elements = Object.values(folders).map((packageInfo) => ({
        ...state[consolidatedLenderSlice.name].elements.entities[packageInfo.elementId],
        id: packageInfo.id,
        parentId: packageInfo.parentInfoId ?? null,
        elementId: packageInfo.elementId,
        visibleAtStatus: packageInfo.visibleAtStatus,
        childrenIds: packageInfo.childrenIds,
        title: packageInfo.title,
        priorityType: packageInfo.priorityType,
    }));

    const parentId = elements.find((folder) => !folder.parentId)?.id;

    if (!elements.length || !parentId) return [];

    return elementsTree(elements, 'id', [parentId]);
};
export const {
    selectById: selectConsolidatedFolderById,
    selectIds: selectConsolidatedFolderIds,
    selectEntities: selectFolderEntities,
    selectAll: selectAllFolders,
    selectTotal: selectTotalFolders,
} = foldersAdapter.getSelectors((state: RootState) => state[consolidatedLenderSlice.name]);