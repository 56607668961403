import { toast as reactToast } from 'react-toastify';

export type TToastType = 'info' | 'success' | 'warning' | 'error' | 'default';

type TToast = {
    position?: 'top-right' | 'top-center' | 'top-left' | 'bottom-right' | 'bottom-center' | 'bottom-left',
    content: string,
    duration?: number,
    toastId?: string,
    type?: TToastType,
    hideProgressBar?: boolean,
    onClick?: (event: React.MouseEvent) => void,
};

export const toast = ({
    position = 'bottom-right',
    content,
    onClick,
    hideProgressBar = true,
    toastId,
    duration = 5000,
    type = 'default' }: TToast) => reactToast(<div
        data-toast="true"
        className='[&_a]:text-blue-100 [&_a]:underline a:hover:text-black-primary [&_a]hover:no-underline'
        dangerouslySetInnerHTML={{ __html: content }} />,
        {
            position,
            toastId,
            autoClose: duration,
            onClick: onClick,
            hideProgressBar: hideProgressBar,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            type
        });