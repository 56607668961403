import { fileToBase64 } from "./file-to-base64";

export const convertImageFileToJpegFile = async (file: File) => {
    return new Promise<File>(async (resolve, reject) => {
        const dataUrl = await fileToBase64(file);
        const img = new Image();
        img.onload = async () => {

            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            if (ctx) {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);
                const dataURL = canvas.toDataURL('image/jpeg');
                canvas.remove();
                let finalFile = await fetch(dataURL).then(res => res.blob())
                    .then(blobFile => new File([blobFile], `${file.name}.jpeg`, { type: 'image/jpeg' }));
                resolve(finalFile);
            }
        };
        img.onerror = (error) => {
            reject(error);
        };
        img.src = dataUrl;
    }
    );
}
