import { Document, DocumentUpdateDto, Upload } from 'src/backend';
import { guessFileProviderType } from 'src/utils/document/guess-provider-type';

import { baseApi } from './baseApi';

export const documentApi = baseApi.enhanceEndpoints({ addTagTypes: ['Document'] }).injectEndpoints({
    endpoints: (build) => ({
        getDocumentWithDownloadUrl: build.query<Document, { id: string, loanId?: string, formElementId?: string, password?: string }>({
            query: (data) => ({
                url: `/v1/documents/${data.id}/withDownloadUrl`,
                method: 'GET',
                data,
            }),
            providesTags: (_, __, args) => [{
                type: 'Document',
                id: args.id
            }],
            transformResponse: (response: Document) => {
                return {
                    ...response,
                    downloadUrl: new URL(response.downloadUrl.toString().replace('5000', '3000')).toString(),
                }
            }
        }),
        updateDocument: build.mutation<Document, { id: string, payload: DocumentUpdateDto | null }>({
            query: (data) => ({
                url: `/v1/documents/${data.id}`,
                method: 'PUT',
                data: data.payload,
            }),
            invalidatesTags: (_, __, args) => [{ type: 'Document', id: args.id }],
        }),
        getUploadUrl: build.query<Upload, { name: string }>({
            query: (data) => {
                const providerType = guessFileProviderType(data.name);
                return ({
                    url: `/v1/documents/uploadUrlFor`,
                    method: 'GET',
                    data: {
                        name: data.name,
                        iProviderType: providerType,
                    },
                })
            },
        }),
        postUploadUrl: build.mutation<Document, Upload>({
            query: (data) => ({
                url: `/v1/documents`,
                method: 'POST',
                data: data,
            })
        }),
        downloadFile: build.query<unknown, { id: string }>({
            query: (data) => ({
                url: `/v1/documents/${data.id}/download`,
                method: 'GET',
                responseType: 'blob',
            }),
        }),
        editDocument: build.mutation<Document, DocumentUpdateDto>({
            query: (data) => ({
                url: `/v1/documents/${data.id}`,
                method: 'PUT',
                data: data,
            }),
            invalidatesTags: (_result, _error, { id }) => [{ type: 'Document', id: id }], // invalidates the tag with the session id
        }),
        getDocumentWithEditDownloadUrl: build.query<Document, { id: string, loanId?: string, formElementId?: string, password?: string, editAnonymous?: boolean }>({
            query: (data) => ({
                url: `/v1/documents/${data.id}/withEditDownloadUrl`,
                method: 'GET',
                data,
            })
        }),
    }),
    overrideExisting: true,
})

export const {
    useDownloadFileQuery,
    useLazyDownloadFileQuery,
    useEditDocumentMutation,
    usePostUploadUrlMutation,
    useLazyGetUploadUrlQuery,
    useGetDocumentWithDownloadUrlQuery,
    useLazyGetDocumentWithDownloadUrlQuery,
    useGetDocumentWithEditDownloadUrlQuery,
    useLazyGetDocumentWithEditDownloadUrlQuery,
    useUpdateDocumentMutation
} = documentApi;



