import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react'
import type { AxiosError, AxiosRequestConfig } from 'axios'
import axios from 'axios'
import { API_VERSION } from 'src/constants/api';
import { isServer } from 'src/utils/is-server';

const appPort = (!isServer && window.location.port) ? ':' + window.location.port : '';
export const API_BASE = isServer ? `${process.env.API_BASE_URL}${API_VERSION}` : `${window.location.protocol}//${window.location.hostname}${appPort}${API_VERSION}`;

const axiosBaseQuery = ({ baseUrl }: { baseUrl: string } = { baseUrl: '' }): BaseQueryFn<
    {
        url: string
        method: AxiosRequestConfig['method']
        data?: AxiosRequestConfig['data']
        params?: AxiosRequestConfig['params']
        headers?: AxiosRequestConfig['headers']
    },
    unknown,
    unknown
> =>
    async ({ url, method, data, params, headers }) => {
        try {
            if (method === 'GET') {
                const urlWithArgs = new URL(baseUrl + url)
                Object.keys(data ?? {}).forEach((key) => {
                    // if it's an array, append the same key multiple times with bracket syntax
                    if (Array.isArray(data[key])) {
                        data[key].forEach((val) => {
                            urlWithArgs.searchParams.append(`${key}`, val)
                        })
                    } else {
                        urlWithArgs.searchParams.append(key, data[key])
                    }
                })
                const result = await axios.get(urlWithArgs.toString(), { params, headers })
                return { data: result.data }
            } else if (method === 'POST') {
                const result = await axios.post(baseUrl + url, data, { params, headers })
                return { data: result.data }
            } else if (method === 'PUT') {
                const result = await axios.put(baseUrl + url, data, { params, headers })
                return { data: result.data }
            } else if (method === 'DELETE') {
                const result = await axios.delete(baseUrl + url, { data, params, headers })
                return { data: result.data }
            } else {
                throw new Error(`Method ${method} is not supported`)
            }
        } catch (axiosError) {
            const err = axiosError as AxiosError
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            }
        }
    }

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseApi = createApi({
    baseQuery: axiosBaseQuery({
        baseUrl: API_BASE,
    }),
    reducerPath: 'baseApi',
    endpoints: (builder) => ({
        getUrlContent: builder.query({
            queryFn: async ({ url }) => {
                try {
                    const result = await axios.get(url)
                    return { data: result.data }
                } catch (axiosError) {
                    const err = axiosError as AxiosError
                    return {
                        error: {
                            status: err.response?.status,
                            data: err.response?.data || err.message,
                        },
                    }
                }
            }
        }),
        upload: builder.mutation({
            queryFn: async ({ url, data, onUploadProgress, headers }: { url: string, data: File, headers?: Record<string, string>, onUploadProgress: (progress: number) => void }) => {
                try {
                    const result = await axios.put(url, data, {
                        onUploadProgress: upload => {
                            let uploadedProgress = Math.round((100 * upload.loaded) / upload.total);
                            onUploadProgress(uploadedProgress);
                        },
                        ...headers ? { headers } : {},
                    });
                    return { data: result.data }

                } catch (axiosError) {
                    let err = axiosError
                    return {
                        error: {
                            status: err.response?.status,
                            data: err.response?.data || err.message,
                        },
                    }
                }
            }
        }),
    }),
})

export const { useUploadMutation, useGetUrlContentQuery, useLazyGetUrlContentQuery } = baseApi