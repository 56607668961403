
import { FeKeys, PerUserConstants, VersionDto } from 'src/backend';

import { baseApi } from './baseApi';

export const appApi = baseApi.enhanceEndpoints({ addTagTypes: ['EmployeePublicDetailsDto'] }).injectEndpoints({
    endpoints: (build) => ({
        getAppVersion: build.query<string, void>({
            query: () => ({
                url: '/v1/noauth/version',
                method: 'GET',
            }),
            transformResponse: (response: VersionDto) => {
                if (process.env.NODE_ENV === 'development') {
                    return 'dev';
                }
                return response.version ? response.version?.split('-')?.[0] : "unknown";
            }
        }),
        getEnvVars: build.query<{ SHERPAS_AI_INSTALLATIONROOTURL: string }, void>({
            query: () => ({
                url: '/vars',
                method: 'GET',
            }),
        }),
        getConstants: build.query<PerUserConstants, void>({
            query: () => ({
                url: '/v1/javascriptConstants',
                method: 'GET',
            }),
        }),
        getLicenseKeys: build.query<FeKeys, void>({
            query: () => ({
                url: '/v2/frontend/keys',
                method: 'GET',
            }),
        }),
    }),
    overrideExisting: true,
})

export const {
    useGetAppVersionQuery,
    useGetLicenseKeysQuery,
    useLazyGetAppVersionQuery,
    useGetEnvVarsQuery,
    useGetConstantsQuery,
} = appApi;



