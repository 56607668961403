import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "src/api";

const SLICE_NAME = 'license-keys'

export const fetch3rdPartyLicenseKeys = createAsyncThunk(
    `${SLICE_NAME}/fetch3rdPartyLicenseKeys`, async () => {
        const response = await api.get3PartyLicenseKeys()
        return response
    }
)

export const licenseKeysSlice = createSlice({
    name: SLICE_NAME,
    initialState: {
        loading: false,
        googleMapsKey: '',
        pdftronKey: '',
        syncfusionKey: '',
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetch3rdPartyLicenseKeys.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetch3rdPartyLicenseKeys.fulfilled, (state, action) => {
            state.loading = false
            state.googleMapsKey = action.payload.googleMapsKey
            state.pdftronKey = action.payload.pdftronKey
            state.syncfusionKey = action.payload.syncfusionKey
        })
        builder.addCase(fetch3rdPartyLicenseKeys.rejected, (state) => {
            state.loading = false
        })

    },
})