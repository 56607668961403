import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { api } from 'src/api'
import { ListItem, LoanPhaseListItem } from 'src/constants/lists';
import type { AppThunk, RootState } from 'src/store';

interface ListsState {
    states: ListItem[];
    loanStatuses: ListItem[];
    loanTypes: ListItem[];
    entityTypes: ListItem[];
    knowledgeBases: ListItem[];
    systemUsersIds: string[];
    priorities: ListItem[];
    phaseCategories: LoanPhaseListItem[];
}

const initialState: ListsState = {
    states: [],
    loanStatuses: [],
    loanTypes: [],
    entityTypes: [],
    knowledgeBases: [],
    systemUsersIds: [],
    priorities: [],
    phaseCategories: [],
};

export const listsSlice = createSlice({
    name: 'lists',
    initialState,
    reducers: {
        getStatesSuccess(state: ListsState, action: PayloadAction<ListItem[]>): void {
            state.states = action.payload
        },
        getJavascriptConstantsSuccess(state: ListsState, action: PayloadAction<{
            states: ListItem[],
            loanStatuses: ListItem[],
            loanTypes: ListItem[],
            entityTypes: ListItem[],
            knowledgeBases: ListItem[],
            systemUsersIds: string[],
            priorities: ListItem[],
            phaseCategories: LoanPhaseListItem[],
        }>): void {
            if (action.payload.states.length > 0) {
                state.states = action.payload.states;
            }
            if (action.payload.loanStatuses.length > 0) {
                state.loanStatuses = action.payload.loanStatuses;
            }
            if (action.payload.loanTypes.length > 0) {
                state.loanTypes = action.payload.loanTypes;
            }
            if (action.payload.entityTypes.length > 0) {
                state.entityTypes = action.payload.entityTypes;
            }

            if (action.payload.priorities.length > 0) {
                state.priorities = action.payload.priorities;
            }

            if (action.payload.phaseCategories.length > 0) {
                state.phaseCategories = action.payload.phaseCategories;
            }
            // sort knowledge bases alphabetically
            state.knowledgeBases = action.payload.knowledgeBases
                .sort((a, b) => a.value.localeCompare(b.value));

            state.systemUsersIds = action.payload.systemUsersIds;
        }
    }
});

export const { reducer } = listsSlice;

export const getJavascriptConstants = (): AppThunk => async (dispatch): Promise<void> => {
    try {
        const {
            states,
            loanStatuses,
            loanTypes,
            entityTypes,
            knowledgeBases,
            systemUsersIds,
            priorities,
            phaseCategories
        } = await api.getJavascriptConstants();
        dispatch(listsSlice.actions.getJavascriptConstantsSuccess({
            states,
            loanStatuses,
            loanTypes,
            entityTypes,
            knowledgeBases,
            systemUsersIds,
            priorities,
            phaseCategories
        }))
    } catch (err) {
    }
};

export const listsLoanTypesSelector = (state: RootState) => state.lists.loanTypes;
export const listsEntityTypesSelector = (state: RootState) => state.lists.entityTypes;
export const listsKnowledgeBasesSelector = (state: RootState) => state.lists.knowledgeBases;
export const phaseCategoriesList = createSelector((state: RootState) => state.lists.phaseCategories, (phaseCategories) => phaseCategories);
