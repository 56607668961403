import 'react-medium-image-zoom/dist/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'components/dist/style.css';
import './global.css';

import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script'
import type { FC } from 'react';
import React from 'react';
import { ONE_TRUST_PROFILE_ID } from 'src/constants/secrets';
import { AppProvider } from 'src/providers/app-provider';
import { isProductionDomain } from 'src/utils/is-production-domain';


type EnhancedAppProps = AppProps & {
  Component: NextPage;
}

const App: FC<EnhancedAppProps> = (props) => {
  const { Component, pageProps } = props;
  const getLayout = Component.getLayout ?? ((page) => page);

  return (<>
    {isProductionDomain() && (<>
      <Script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        strategy="lazyOnload"
        data-domain-script={ONE_TRUST_PROFILE_ID} />
      <Script
        id="onetrust"
        dangerouslySetInnerHTML={{
          __html: `function OptanonWrapper() { }`,
        }} />
    </>)}
    <Head>
      <title>
        mysherpas
      </title>
      <meta
        name="viewport"
        content="initial-scale=1, width=device-width"
      />
    </Head>
    <AppProvider>
      {getLayout(<Component {...pageProps} />)}
    </AppProvider>
  </>
  );
};

export default App;